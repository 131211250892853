@keyframes loading-dots {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-1rem);
  }
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}

.loading-dots span {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #333;
  border-radius: 50%;
  margin: 0 0.25rem;
  animation: loading-dots 1s infinite ease-in-out;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.8s;
}

.loading-dots span:nth-child(3) {
  animation-delay: -0.6s;
}
